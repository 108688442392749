import { Component, HostListener } from '@angular/core';
import { environment } from '@environments/environment';
import { DimensionsScreenService } from '@services/global/dimensions-screen.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent {
	@HostListener('window:resize', ['$event'])
	onWindowResize() {
		this.dimensionsScreenSrc.data.next({
			...this.dimensionsScreenSrc.data.getValue(),
			width: window.innerWidth,
			height: window.innerHeight,
		});
	}

	@HostListener('window:load', ['$event'])
	onWindowLoad() {
		this.dimensionsScreenSrc.data.next({
			...this.dimensionsScreenSrc.data.getValue(),
			width: window.innerWidth,
			height: window.innerHeight,
		});
	}

	constructor(private dimensionsScreenSrc: DimensionsScreenService) {
		this.loadScriptContent();
	}

	loadScriptContent() {
		const head = document.head;
		const scripts = [environment.urlCdnContent, environment.cdnWidget + '/autoload.js'];

		for (let i = 0; i < scripts.length; i++) {
			const src = scripts[i];
			const script = document.createElement('script');
			script.src = src;
			head.appendChild(script);
		}
	}
}
