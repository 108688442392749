import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
	ElementRef,
} from '@angular/core';

@Component({
	selector: 'vl-select-custom',
	templateUrl: './vl-select-custom.component.html',
	styleUrls: ['./vl-select-custom.component.scss'],
})
export class VlSelectCustomComponent implements AfterViewInit, OnChanges {
	@Input('config') config: { type?: 'text' | 'icon'; isMulti?: boolean; options: Array<any> } = {
		type: 'text',
		isMulti: false,
		options: [],
	};

	_config: { type?: 'text' | 'icon'; isMulti?: boolean; options: Array<any> } = {
		type: 'text',
		isMulti: false,
		options: [],
	};

	split = 0;

	@Input('value') value: string | Array<string> = '';
	@Input('customClass') customClass: string = '';
	@Input('placeholder') placeholder: string = '';
	@Output('changeSelection') changeSelection: EventEmitter<string | boolean | number | Array<string | boolean | number>> = new EventEmitter<
		string | boolean | number | Array<string | boolean | number>
	>();
	@Output('onBlur') onBlur: EventEmitter<any> = new EventEmitter<any>();
	public classMode: string = 'vlTk__control--select';
	public openDrawer: boolean = false;
	public labels: { [value: string]: string | boolean | number } = {};
	public labelView: string = '';
	public coordinateOptions: any = {};
	public myCoordinate: {
		x: number;
		y: number;
		width: number;
		height: number;
		top: number;
		right: number;
		bottom: number;
		left: number;
	} = {
		x: 0,
		y: 0,
		width: 0,
		height: 0,
		top: 0,
		right: 0,
		bottom: 0,
		left: 0,
	};

	constructor(private cb: ChangeDetectorRef) {}

	ngAfterViewInit(): void {
		this.config = {
			type: this.config.type || 'text',
			isMulti: this.config.type === 'icon' ? false : this.config?.isMulti || false,
			options: this.config.options || [],
		};

		this.setClassMode();

		this.config.options?.map((option) => {
			this.labels[option.value.toString()] = option.label;
		});

		this._config = { ...this.config };

		this._config.options = [...this.config.options].splice(0, 10);
		this.split = 0;

		this.cb.detectChanges();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['value']) {
			if (this.value) {
				this.buildLabels();
				if (this.config.isMulti) {
					if (this.value.length === 0) {
						this.labelView = '';
					} else if (this.value.length === 1) {
						this.labelView = this.labels[this.value[0]].toString();
					} else {
						this.labelView = `${this.labels[this.value[0]].toString()} y ${this.value.length - 1} más`;
					}
				} else {
					this.labelView = this.labels[this.value.toString()]?.toString() || '';
				}
			} else {
				this.labelView = '';
			}
		}
		if (changes['config']) {
			if (this.config.options?.length === 0) this.config.options = [{ value: '_', label: 'No hay opciones disponibles', disabled: true }];
			this.buildLabels();
		}
	}

	buildLabels() {
		this.config.options?.map((option) => {
			this.labels[option.value.toString()] = option.label;
		});
		this._config.options = [...this.config.options].splice(0, 10);
		this.split = 0;
	}

	setClassMode() {
		if (this.config.type !== 'text') {
			this.classMode = 'vlTk__control--selectIcon';
		} else if (this.config.isMulti) {
			this.classMode = 'vlTk__control--selectMulti';
		} else {
			this.classMode = 'vlTk__control--select';
		}
	}

	returnSelection(optionSelected: string | Array<string>) {
		let options: any = [optionSelected].flat(Infinity);

		if (this.config.isMulti) {
			if (optionSelected.length === 0) {
				this.labelView = '';
			} else if (optionSelected.length === 1) {
				this.labelView = this.labels[optionSelected[0]].toString();
			} else {
				this.labelView = `${this.labels[optionSelected[0]].toString()} y ${optionSelected.length - 1} mas`;
			}
		} else {
			this.labelView = this.labels[optionSelected[0]]?.toString() || '';
		}

		options.forEach((option: string) => {
			if (option === 'true') {
				return true;
			} else if (option === 'false') {
				return false;
			} else if (!isNaN(Number(option))) {
				return Number(option);
			} else {
				return option;
			}
		});

		this.value = this.config.isMulti ? options : options[0] || '';

		this.changeSelection.emit(this.value);
	}

	checkBoxOption() {
		if (this._config.options.length === this.config.options.length) {
			return;
		}
		this.split += 10;
		this._config.options = [...this._config.options, ...[...this.config.options].splice(this.split, 10)];
	}

	toggleOptions(input: HTMLDivElement) {
		if (this.config.options.length > 0) this.openDrawer = !this.openDrawer;
		this.myCoordinate = input.getBoundingClientRect();

		const boxOptions = this.config.options.length * 47 > 264 ? 264 : this.config.options.length * 47;

		if (this.myCoordinate.y + boxOptions < document.documentElement.clientHeight) {
			this.myCoordinate.y = this.myCoordinate.y + 48;
		} else {
			this.myCoordinate.y = this.myCoordinate.y - boxOptions - 6;
		}
	}
}
