// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	apiServer: 'https://api.dev.tk.videolink.app',
	adminUrl: 'https://admin.dev.tk.videolink.app',
	agentUrl: 'https://agent.dev.tk.videolink.app',
	easyUrl: 'https://easy-ticket-dev.web.app/conditions',
	wsServer: 'https://ws.dev.tk.videolink.app/videolink', //Desactivado a la fecha
	storage: 'https://cdn.dev.tk.videolink.app',
	vlTokenSupport: '8b856fee2b4bdaa534c869092584cd4564a1a59c123b9a20a96b51178d132729',
	urlCdnContent: 'https://content.tk.videolink.app/autoload.js',
	cdnFiles: 'https://content.dev.tk.videolink.app',
	cdnWidget: 'https://widget.tk.dev.videolink.app',
	mode: 'dev',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
