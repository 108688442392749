import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService } from '@services/global/auth.service';

@Directive({
	selector: '[wTP]',
})
export class WithThesePermissionDirective {
	@Input() set wTP(permissions: Array<string>) {
		if (permissions.length > 0) this.permissions = permissions;
		this.updateView();
	}
	permissions: Array<string> = ['*'];
	constructor(private templateRef: TemplateRef<any>, private viewContainerRef: ViewContainerRef, private _authSrc: AuthService) {}

	private updateView(): void {
		this.viewContainerRef.clear();
		if (this.checkPermissions()) {
			this.viewContainerRef.createEmbeddedView(this.templateRef);
		}
	}
	private checkPermissions(): boolean {
		if (this.permissions.includes('*')) return true;
		else return this._authSrc.haveAnyPermission(...this.permissions);
	}
}
